body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.error {
    background-color: #ffe7e7;
    border: 1px solid #ddbbbb;
    padding: 8px;
    border-radius: 4px;
    word-break: break-word;
    font-size: 0.75em;
    margin-bottom: 10px;
    color: #666;
}

.message {
    /*border: 1px solid #ccc;*/
    padding: 8px;
    /*border-radius: 4px;*/
    word-break: break-word;
    font-size: 0.85em;
    margin-bottom: 10px;
    color: #666;
}

.loading {
    width: 150px;
    margin: 0;
    position: absolute;
    top: 25%;
    left: 50%;
    margin-right: -50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.content {
    display: flex;
    flex-direction: column;
    border: 1px solid #eee;
    padding: 25px;
    border-radius: 4px;
}

.logo {
    width: 150px;
}

.container {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
}

.main {
    grid-column-start: 2;

}

textarea {
    margin-bottom: 15px;
    width: 100%;
    height: 200px;
    padding: 10px 18px 0 18px;
    background: #eee;
    border: 1px solid #707070;
    font-size: 12px;
    font-family: monospace;
    color: #666;
    outline: none;
    border-radius: 4px;
    box-sizing: border-box;
    resize: none;
}

button {
    border: 1px solid #8cc640;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    transition: all 200ms;
    color: white;
    background: #8cc640;
    border-radius: 5px;
    cursor: pointer;
    padding: 10px 20px;
    margin: 5px;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

button:disabled {
    background: #eaeaea;
    color: #cccccc;
    border-color: #eaeaea;
}

button:focus {
    outline: none;
}

header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

header>h1 {
    margin-left: 15px;
    color: #666666;
    font-size: 2.2em;
}
